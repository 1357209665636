<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? venueMnagement.fiscal_name_bn : venueMnagement.fiscal_name }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_tpm.committee_team')}} {{ $t('globalTrans.type')}}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? venueMnagement.committee_name_bn : venueMnagement.committee_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 2">{{ $t('elearning_tpm.vanue')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 2" colspan="3">{{ ($i18n.locale=='bn') ? venueMnagement.venue_bn : venueMnagement.venue_name }}</b-td>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 1">{{ $t('elearning_config.training_type')}} {{ $t('globalTrans.type')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 1">{{ ($i18n.locale=='bn') ? venueMnagement.training_type_bn : venueMnagement.training_type_en }}</b-td>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 1">{{ $t('elearning_config.training_category')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 1">{{ ($i18n.locale=='bn') ? venueMnagement.training_category_bn : venueMnagement.training_category_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 1">{{ $t('elearning_config.training_title')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 1" colspan="3">{{ ($i18n.locale=='bn') ? venueMnagement.training_title_bn : venueMnagement.training_title_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 1">{{ $t('elearning_iabm.batch_no')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 1" colspan="3">{{ ($i18n.locale=='bn') ? venueMnagement.batch_bn : venueMnagement.batch_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%" v-if="venueMnagement.committee_type == 1">{{ $t('elearning_iabm.circular_memo_no')}}</b-th>
                                <b-td style="width:30%" v-if="venueMnagement.committee_type == 1" colspan="3">{{ venueMnagement.circular_memo_no }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <b-row>
                      <b-col lg="12" sm="12" class="text-left mt-1 mb-3">
                        <h4 class='complain-title pl-3'>{{ $t('elearning_tpm.committee_team') }}  {{ $t('sidebar.information')}}</h4>
                      </b-col>
                    </b-row>
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th style="width:25%">{{ $t('globalTrans.name') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.designation') }}</b-th>
                            <b-th style="width:25%">{{ $t('elearning_config.committee_position') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(item,index) in venueMnagement.details" :key="index">
                            <b-td style="width:20%">{{ getCommitteeMember(item.member_id) }}</b-td>
                            <b-td style="width:20%">{{ getDesignation(item.designation_id) }}</b-td>
                            <b-td style="width:20%">{{ ($i18n.locale=='bn') ? item.committee_position_bn : item.committee_position_en }}</b-td>
                        </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { designationWiseUser } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.venueMnagement = this.item
    this.getOfficerList()
  },
  data () {
    return {
      venueMnagement: [],
      slOffset: 1,
      allOfficerList: [],
      officerLoading: false
    }
  },
  computed: {
  },
  methods: {
    async getOfficerList () {
        this.officerLoading = true
          const params = {
            org_id: parseInt(this.venueMnagement.org_id),
            office_type_id: parseInt(0)
          }
          await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
          .then(response => {
            if (response.success) {
              this.allOfficerList = response.data
            }
            this.officerLoading = false
        })
      },
      getDesignation (id) {
        const office = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
      },
      getCommitteeMember (id) {
        const officer = this.allOfficerList.find(item => parseInt(item.user_id) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
            return officer !== undefined ? officer.name_bn : ''
        } else {
            return officer !== undefined ? officer.name : ''
        }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
