<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.committee_team') }}  {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.fiscal_year')}}
              </template>
              <v-select name="organization"
                v-model="search.fiscal_year_id"
                label="text"
                :reduce="item => item.value"
                :options= fiscalYearList
                :placeholder="$t('globalTrans.select')"
                :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('elearning_tpm.committee_team') + ' ' + $t('globalTrans.type')"
                label-for="committee_type"
            >
              <v-select name="CommitteeType"
                  v-model="search.committee_type"
                  label="text"
                  :reduce="item => item.value"
                  :options= committeeTypeList
                  :placeholder="$t('globalTrans.select')"
                  :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('org_pro.organization')"
                label-for="org_id"
            >
              <v-select name="orgId"
                  v-model="search.org_id"
                  label="text"
                  :reduce="item => item.value"
                  :options= orgList
                  :placeholder="$t('globalTrans.select')"
                  :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_tpm.committee_team') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(batch_no)="data">
                      {{ data.batch_no }}
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_tpm.committee_team') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <Details :item="item" :key="id"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { masterCommitteeList, masterCommitteeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Details from './Details'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
      search: {
        org_id: '',
        fiscal_year_id: '',
        committee_type: ''
      },
      id: 0,
      item: '',
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  computed: {
      allBatchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    committeeTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.committeeType
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_tpm.committee_team') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_tpm.committee_team') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
          { label: this.$t('elearning_tpm.committee_team') + ' ' + this.$t('globalTrans.type'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_name_bn' },
          { key: 'org_bn' },
          { key: 'training_title_bn' },
          { key: 'batch_bn' },
          { key: 'committee_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_name' },
          { key: 'org' },
          { key: 'training_title_en' },
          { key: 'batch_en' },
          { key: 'committee_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
    })
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, masterCommitteeToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, masterCommitteeList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const fiscalList = this.$store.state.commonObj.fiscalYearList
      const committeeTypeList = this.$store.state.TrainingElearning.commonObj.committeeType
      const listData = data.map(item => {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const titleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          titleData.training_title_en = trainingTitleObj.text_en
          titleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          titleData.training_title_en = ''
          titleData.training_title_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const categoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          categoryData.training_category_en = trainingCategoryObj.text_en
          categoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          categoryData.training_category_en = ''
          categoryData.training_category_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const typeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          typeData.training_type_en = trainingTypeObj.text_en
          typeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          typeData.training_type_en = ''
          typeData.training_type_bn = ''
        }
        const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
        const batchData = {}
        if (typeof batchObj !== 'undefined') {
          batchData.batch_en = batchObj.text_en
          batchData.batch_bn = batchObj.text_bn
        } else {
          batchData.batch_en = ''
          batchData.batch_bn = ''
        }
        const venueObj = this.$store.state.TrainingElearning.commonObj.venus.find(doc => doc.value === parseInt(item.venue_id))
        const venueData = {}
        if (typeof venueObj !== 'undefined') {
          venueData.venue_en = venueObj.text_en
          venueData.venue_bn = venueObj.text_bn
        } else {
          venueData.venue_en = ''
          venueData.venue_bn = ''
        }
        const fiscalObj = fiscalList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const committeeObj = committeeTypeList.find(committee => committee.value === parseInt(item.committee_type))
        const fiscalData = { fiscal_name: fiscalObj !== undefined ? fiscalObj.text_en : '', fiscal_name_bn: fiscalObj !== undefined ? fiscalObj.text_bn : '' }
        const committeeData = { committee_name: committeeObj !== undefined ? committeeObj.text_en : '', committee_name_bn: committeeObj !== undefined ? committeeObj.text_bn : '' }
        return Object.assign({}, item, committeeData, fiscalData, orgData, titleData, typeData, categoryData, venueData, batchData)
      })
      return listData
    }
  }
}
</script>
