<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                    <b-row>
                                        <b-col lg="6" sm="6">
                                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="fiscal_year_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                                </template>
                                                <!-- <b-form-select
                                                    plain
                                                    v-model="committee.fiscal_year_id"
                                                    :options="fiscalYearList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select> -->
                                                <v-select
                                                  id="fiscal_year_id"
                                                  v-model="committee.fiscal_year_id"
                                                  :reduce="op => op.value"
                                                  :options="fiscalYearList"
                                                  :placeholder="$t('globalTrans.select')"
                                                  :filter-by="myFilter"
                                                  label="text"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </v-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="committee.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Committee Type"  vid="committee_type" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="committee_type"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('elearning_tpm.committee_team')}} {{ $t('globalTrans.type')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <!-- <b-form-select
                                                    plain
                                                    v-model="committee.committee_type"
                                                    :options="committeeTypeList"
                                                    id="committee_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select> -->
                                                    <v-select
                                                      id="committee_type"
                                                      v-model="committee.committee_type"
                                                      :reduce="op => op.value"
                                                      :options="committeeTypeList"
                                                      :placeholder="$t('globalTrans.select')"
                                                      :filter-by="myFilter"
                                                      label="text"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </v-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- <b-col lg="6" sm="12" v-if="committee.committee_type === 2">
                                            <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="venue_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('elearning_tpm.vanue')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="committee.venue_id"
                                                    :options="venueList"
                                                    id="venue_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 2">
                                          <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="venue_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_tpm.vanue')}}
                                                  </template>
                                                  <!-- <v-select name="venue"
                                                      v-model="committee.venue_id"
                                                      label="text"
                                                      :reduce="item => item.value"
                                                      :options= venueList
                                                      :placeholder="$t('globalTrans.select')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  /> -->
                                                  <v-select
                                                    id="venue_id"
                                                    v-model="committee.venue_id"
                                                    :reduce="op => op.value"
                                                    :options="venueList"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :filter-by="myFilter"
                                                    label="text"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  </v-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                      </b-col>
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 1">
                                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="training_type_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_config.training_type')}}
                                                  </template>
                                                  <!-- <v-select name="training_type_id"
                                                      v-model="committee.training_type_id"
                                                      label="text"
                                                      :reduce="item => item.value"
                                                      :options= trainingTypeList
                                                      :placeholder="$t('globalTrans.select')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  /> -->
                                                  <v-select
                                                    id="training_type_id"
                                                    v-model="committee.training_type_id"
                                                    :reduce="op => op.value"
                                                    :options="trainingTypeList"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :filter-by="myFilter"
                                                    label="text"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  </v-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                      </b-col>
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 1">
                                          <ValidationProvider name="Training Category"  vid="training_category_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="training_category_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_config.training_category')}}
                                                  </template>
                                                  <!-- <v-select name="training_category_id"
                                                      v-model="committee.training_category_id"
                                                      label="text"
                                                      :reduce="item => item.value"
                                                      :options= trainingCategoryList
                                                      :placeholder="$t('globalTrans.select')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  /> -->
                                                  <v-select
                                                    id="training_category_id"
                                                    v-model="committee.training_category_id"
                                                    :reduce="op => op.value"
                                                    :options="trainingCategoryList"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :filter-by="myFilter"
                                                    label="text"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  </v-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                      </b-col>
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 1">
                                          <ValidationProvider name="Training Title"  vid="training_title_id" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="training_title_id"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('elearning_config.training_title')}}
                                                  </template>
                                                  <!-- <v-select name="venue"
                                                      v-model="committee.training_title_id"
                                                      label="text"
                                                      :reduce="item => item.value"
                                                      :options= trainingTitleList
                                                      :placeholder="$t('globalTrans.select')"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                  /> -->
                                                  <v-select
                                                    id="training_title_id"
                                                    v-model="committee.training_title_id"
                                                    :reduce="op => op.value"
                                                    :options="trainingTitleList"
                                                    :placeholder="$t('globalTrans.select')"
                                                    :filter-by="myFilter"
                                                    label="text"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  </v-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                      </b-col>
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 1">
                                        <ValidationProvider name="Batch no"  vid="batch_no" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="batch_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_iabm.batch_no')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="committee.batch_no"
                                                :options="batchList"
                                                id="batch_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12" v-if="committee.committee_type === 1">
                                        <ValidationProvider name="Circular memo no"  vid="circular_memo_no" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="circular_memo_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('elearning_iabm.circular_memo_no')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="committee.circular_memo_no"
                                                :options="circularList"
                                                id="circular_memo_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12" sm="12" class="text-left mt-3 mb-2">
                                            <h4 class='complain-title pl-3'>{{ $t('elearning_tpm.committee_team') }}  {{ $t('sidebar.information')}}</h4>
                                        </b-col>
                                    </b-row>
                                    <b-row v-for="(info, index) in committee.details" :key="index" class="mt-3">
                                    <!-- <pre>{{ info }}</pre> -->
                                    <b-col lg="3" md="3" sm="12" xs="12">
                                        <ValidationProvider name="Office" :vid="'office_id' + index" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="`office$(index)`"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.office') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="info.office_id"
                                                :options="info.officeList"
                                                @change="designationListFound(info)"
                                                :id="'info['+index+'][office_id]'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="12" xs="12">
                                        <ValidationProvider name="Designation" :vid="'designation_id' + index" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="`designation$(index)`"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.designation') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="info.designation_id"
                                                :options="info.designationList"
                                                @change="officerListFound(info)"
                                                :id="'info['+index+'][designation_id]'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="12" xs="12">
                                        <ValidationProvider name="Coordinator"  :vid="'member_id' + index" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="`coordinator$(index)`"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>{{ $t('globalTrans.name') }}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="info.member_id"
                                                :options="officerListx.filter(item => item.designation_id === info.designation_id)"
                                                :id="'info['+index+'][member_id]'"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="6" xs="6">
                                        <ValidationProvider name="Committee Position (En)" vid="committee_position_en" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="committee_position_en"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_config.committee_position') }} {{'('}}{{ $t('globalTrans.en') }}{{')'}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="committee_position_en"
                                                v-model="info.committee_position_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="6" xs="6">
                                        <ValidationProvider name="Committee Position (Bn)" vid="committee_position_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="committee_position_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_config.committee_position') }} {{'('}}{{ $t('globalTrans.bn') }}{{')'}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="committee_position_bn"
                                                v-model="info.committee_position_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:5%">
                                        <b-button v-show="index == committee.details.length-1" variant=" iq-bg-success" size="sm" @click="addMat()"><i class="ri-add-line m-0"></i>{{$t('dae_config.add_more')}}</b-button>
                                        <b-button v-show="index || ( !index && committee.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeMat(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                    </b-col>
                                    </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { masterCommitteeStore, masterCommitteeUpdate, circularList, circularPublicationBatch, designationWiseUser } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getOfficerX()
    if (this.id) {
      const tmp = this.getEditingData()
      this.committee = tmp
      const listData = tmp.details.map(item => {
        const customItem = {}
        customItem.officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === this.committee.org_id && item.office_type_id === 86)
        customItem.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === this.committee.org_id)
        customItem.officerList = this.allOfficerList.filter(itm => itm.designation_id === parseInt(item.designation_id))
        return Object.assign({}, item, customItem)
      })
      tmp.details = listData
       if (tmp.details.length === 0 && tmp.org_id === 12) {
        tmp.details = [
          {
            office_id: 0,
            designation_id: 0,
            member_id: 0
          }
        ]
      }
      this.committee = tmp
    } else {
      this.committee = Object.assign({}, this.committee, {
        org_id: this.$store.state.dataFilters.orgId
      })
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.committee.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      committee: {
        committee_type: '',
        fiscal_year_id: '',
        org_id: '',
        office_type_id: 0,
        office_id: 0,
        venue_id: '',
        training_title_id: '',
        training_category_id: '',
        training_type_id: '',
        batch_no: 0,
        circular_memo_no: '',
        details: [
          {
            office_id: 0,
            designation_id: 0,
            member_id: 0,
            officeList: [],
            designationList: [],
            officerList: [],
            committee_position_en: '',
            committee_position_bn: ''
          }
        ]
      },
      trainingCategoryList: [],
      trainingTitleList: [],
      batchList: [],
      circularList: [],
      circularLoading: false,
      allBatchListData: [],
      officerListx: [],
      allOfficerList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }

    }
  },
  computed: {
      allBatchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    venueList: function () {
        return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1 && item.org_id === this.committee.org_id)
    },
    trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === this.committee.org_id)
    },
    // trainingCategoryList: function () {
    //     return this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1 && item.org_id === this.committee.org_id)
    // },
    // trainingTitleList: function () {
    //     return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1 && item.org_id === this.committee.org_id)
    // },
    committeeTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.committeeType
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
      'committee.training_type_id': function (newValue) {
          this.trainingCategoryList = this.getTrainingCategoryList(newValue)
      },
      'committee.training_category_id': function (newValue) {
          this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'committee.org_id': function (newValue) {
          this.trainingTitleList = this.getOrgTrainingTitleList(newValue)
        this.getOfficerList()
        const listData = this.committee.details.map(item => {
          const customItem = {}
          customItem.officeList = this.$store.state.commonObj.officeList.filter(item => item.org_id === this.committee.org_id && item.office_type_id === 86 && item.status === 0)
          return Object.assign({}, item, customItem)
        })
        this.committee.details = listData
      },
    'committee.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getCircularList()
        }
    },
    'committee.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getCircularList()
        }
    },
    'committee.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.committee.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    }
  },
  methods: {
    addMat () {
      const tampbpSetup = {
            office_id: 0,
            designation_id: 0,
            member_id: 0,
            officeList: this.$store.state.commonObj.officeList.filter(item => item.org_id === this.committee.org_id && item.office_type_id === 86 && item.status === 0)
      }
      const key1 = parseInt(this.committee.details.length - 1)
      const item = this.committee.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.committee.details.push(tampbpSetup)
      }
    },
    removeMat (key) {
      this.committee.details.splice(key, 1)
    },
    designationListFound (info) {
      info.designationList = this.$store.state.commonObj.designationList.filter(item => item.office_id === parseInt(info.office_id))
      return info
    },
    officerListFound (info) {
      const officeList = this.allOfficerList.filter(item => item.designation_id === parseInt(info.designation_id))
      info.officerList = officeList.map(item => {
        return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
      })
      return info
    },
    async getOfficerX () {
        const params = {
          org_id: parseInt(this.committee.org_id),
          office_type_id: parseInt(this.committee.office_type_id),
          office_id: parseInt(this.committee.office_id)
        }
        await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
        .then(response => {
          if (response.success) {
            this.allOfficerList = response.data
          }
      })
      const officeListxx = this.allOfficerList.map(item => {
        return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name, text_en: item.name, text_bn: item.name_bn, designation_id: item.designation_id })
      })
        this.officerListx = officeListxx
    },
    async getOfficerList () {
      this.officerLoading = true
        const params = {
          org_id: parseInt(this.committee.org_id),
          office_type_id: parseInt(this.committee.office_type_id),
          office_id: parseInt(this.committee.office_id)
        }
        await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
        .then(response => {
          if (response.success) {
            this.allOfficerList = response.data
          }
          this.officerLoading = false
      })
    },
      async getCircularList () {
        if (this.committee.fiscal_year_id && this.committee.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.committee.fiscal_year_id,
            training_type_id: this.committee.training_type_id,
            training_category_id: this.committee.training_category_id,
            training_title_id: this.committee.training_title_id,
            coordinator_id: this.committee.coordinator_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
          } else {
            this.allBatchListData = result.batchList
            this.batchInfo()
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
    async batch () {
      if (this.committee.fiscal_year_id && this.committee.training_title_id) {
        this.loading = true
        if (this.committee.org_id === 16) {
          const params = {
            id: this.committee.id,
            training_title_id: this.committee.training_title_id,
            fiscal_year_id: this.committee.fiscal_year_id,
            org_id: this.committee.org_id,
            office_id: this.committee.office_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationBatch, params)
          if (result.success) {
            this.loading = false
            this.committee.batch_no = result.data
          } else {
            this.committee.batch_no = ''
            this.loading = false
          }
        } else {
          const params = {
            id: this.committee.id,
            training_title_id: this.committee.training_title_id,
            fiscal_year_id: this.committee.fiscal_year_id,
            org_id: this.committee.org_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationBatch, params)
          if (result.success) {
            this.loading = false
            this.committee.batch_no = result.data
          } else {
            this.committee.batch_no = ''
            this.loading = false
          }
        }
      }
    },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.committee.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.committee.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.committee.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
    addItem () {
      const obj = {
        name_en: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        committee_position_en: '',
        committee_position_bn: '',
        mobile_no: '',
        email: ''
      }
      const key1 = parseInt(this.committee.details.length - 1)
     const item = this.committee.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item.name_en === '' || item.name_bn === '' || item.designation_en === '' || item.designation_bn === '' || item.committee_position_en === '' || item.committee_position_bn === '' || item.mobile_no === '') {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.committee.details.push(obj)
      }
    },
    getTrainingCategoryList (type) {
        const cat = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (type) {
            return cat.filter(item => item.training_type_id === type)
        }
        return cat
    },
    getTrainingTitleList (cat) {
        const title = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (cat) {
            return title.filter(item => item.training_category_id === cat)
        }
        return title
    },
    getOrgTrainingTitleList (orgId) {
        const title = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (orgId) {
            return title.filter(item => item.org_id === orgId)
        }
        return title
    },
    trainingTitleDetails () {
        const title = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.committee.training_title_id)
        this.committee.training_type_id = title.training_type_id
        this.committee.training_category_id = title.training_category_id
    },
    remove (key) {
      this.committee.details.splice(key, 1)
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.committee.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${masterCommitteeUpdate}/${this.id}`, this.committee)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, masterCommitteeStore, this.committee)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
